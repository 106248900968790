var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { isSupervisor } from "@/app/utils/Utils";
import { STORE } from "@/store";
import { customElement, LitElement, property } from "lit-element";
import { html, nothing } from "lit-html";
import { ifDefined } from "lit-html/directives/if-defined";
let AgentXOutdialComp = class AgentXOutdialComp extends LitElement {
    constructor() {
        super(...arguments);
        this.isOutboundEnabledForTenant = STORE.agent.isOutboundEnabledForTenant;
        this.isOutboundEnabledForAgent = STORE.agent.isOutboundEnabledForAgent;
        this.getOutdialRegex = STORE.agent.getOutdialRegex;
        this.dnNumber = STORE.agent.dnNumber;
        this.selectedOutdialANI = STORE.agent.selectedOutdialANI;
        this.outDialEp = STORE.agent.outDialEp;
        this.isAdhocDialingEnabled = STORE.agent.isAdhocDialingEnabled;
        this.isActiveCall = STORE.agentContact.isActiveCall;
        this.outDialAni = STORE.agent.getOutDialAni;
        this.addressBook = STORE.agent.getAddressBook;
        this.cmsConfigFFEnabled = STORE.app.featureFlags.isCMSConfigMigrated;
        this.isOutdialSpecialCharsEnabled = STORE.app.featureFlags.isOutdialSpecialCharsEnabled;
        this.isDarkModeEnable = STORE.app.darkMode;
        this.isOutdialDisabledForExclusive = STORE.agentContact.shouldDisableOutdialForExclusive;
        this.webRtcStatus = STORE.agent.webRtcStatus;
        this.isAddressBookInfiniteScrollEnabled = STORE.app.featureFlags.isAddressBookInfiniteScrollEnabled;
    }
    render() {
        if (!isSupervisor()) {
            return html `
        <agentx-react-out-dial
          .outDialAni="${this.outDialAni}"
          .addressBook="${this.addressBook}"
          isAddressBookInfiniteScrollEnabled="${this.isAddressBookInfiniteScrollEnabled}"
          shouldDisableOutdialForExclusive="${this.isOutdialDisabledForExclusive}"
          isOutDialEnabledTenant="${this.isOutboundEnabledForTenant}"
          isOutDialEnabledAgent="${this.isOutboundEnabledForAgent}"
          phoneValidateRegex="${this.getOutdialRegex}"
          agentDn="${this.dnNumber}"
          outdialEntryPointId="${this.outDialEp}"
          isAdhocDialingEnabled="${this.isAdhocDialingEnabled}"
          isCallInProgress="${this.isActiveCall}"
          orgId="${STORE.agent.orgId}"
          .selectedANI="${this.selectedOutdialANI}"
          cmsConfigFFEnabled="${this.cmsConfigFFEnabled}"
          @ax-outdial-update-ani-list="${(e) => {
                STORE.agent.updateoutdialAniList(e.detail);
            }}"
          @ax-outdial-update-ani-list-failed="${() => {
                STORE.agent.updateoutdialAniListFailed();
            }}"
          @ax-outdial-fetch-addressbook-list-success=${(e) => {
                STORE.agent.updateAddressbookList(e.detail.data);
            }}
          @ax-outdial-fetch-addressbook-list-failure=${(e) => {
                STORE.agent.updateAddressbookListFailed(e.detail);
            }}
          @ax-outdial-ani=${(e) => {
                STORE.agent.updateSelectedOutdialANI(e.detail.data);
            }}
          isOutdialSpecialCharsEnabled="${this.isOutdialSpecialCharsEnabled}"
          isDarkModeEnable="${this.isDarkModeEnable}"
          webRtcStatus="${this.webRtcStatus}"
          isWebRTCEnabled="${STORE.app.featureFlags.isWebRTCEnabled}"
          deviceType="${ifDefined(STORE.agent.deviceType === null ? undefined : STORE.agent.deviceType)}"
        ></agentx-react-out-dial>
      `;
        }
        else {
            return nothing;
        }
    }
};
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isOutboundEnabledForTenant", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isOutboundEnabledForAgent", void 0);
__decorate([
    property({ type: String })
], AgentXOutdialComp.prototype, "getOutdialRegex", void 0);
__decorate([
    property({ type: String })
], AgentXOutdialComp.prototype, "dnNumber", void 0);
__decorate([
    property({ type: Object })
], AgentXOutdialComp.prototype, "selectedOutdialANI", void 0);
__decorate([
    property({ type: String })
], AgentXOutdialComp.prototype, "outDialEp", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isAdhocDialingEnabled", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isActiveCall", void 0);
__decorate([
    property({ type: Object })
], AgentXOutdialComp.prototype, "outDialAni", void 0);
__decorate([
    property({ type: Object })
], AgentXOutdialComp.prototype, "addressBook", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "cmsConfigFFEnabled", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isOutdialSpecialCharsEnabled", void 0);
__decorate([
    property({ attribute: false })
], AgentXOutdialComp.prototype, "isDarkModeEnable", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isOutdialDisabledForExclusive", void 0);
__decorate([
    property({ type: String })
], AgentXOutdialComp.prototype, "webRtcStatus", void 0);
__decorate([
    property({ type: Boolean })
], AgentXOutdialComp.prototype, "isAddressBookInfiniteScrollEnabled", void 0);
AgentXOutdialComp = __decorate([
    customElement("agentx-outdial")
], AgentXOutdialComp);
export { AgentXOutdialComp };
