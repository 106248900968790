var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { STORE } from "@/store";
import { action, computed, observable } from "mobx";
import { AGENT_STATE_CHANGEDBY_NAME, ALERT_BANNER_DETAILS, STATE_CHANGE_BANNER } from "../app/components/constants";
export class ModuleAlertBanner {
    constructor() {
        this.alertBannerProps = {
            type: "default",
            closable: true,
            message: "",
            display: false,
            id: ""
        };
    }
    updateAlertBanner(value) {
        this.alertBannerProps = Object.assign(Object.assign({}, this.alertBannerProps), value);
    }
    removeStateChangeBanner() {
        // Checks if the banner is agent state changed by supervisor banner
        if (STORE.agent.changedByName !== null && this.alertBannerProps.id === STATE_CHANGE_BANNER) {
            this.updateAlertBanner({
                display: false,
                id: ""
            });
            STORE.agent.updateChangeByName(null);
            const sessionBannerDetails = sessionStorage.getItem(ALERT_BANNER_DETAILS);
            const currentBannerDetails = sessionBannerDetails ? JSON.parse(sessionBannerDetails) : undefined;
            if (currentBannerDetails && currentBannerDetails.reason === AGENT_STATE_CHANGEDBY_NAME) {
                sessionStorage.removeItem(ALERT_BANNER_DETAILS);
            }
        }
    }
    get shouldRenderBanner() {
        return this.alertBannerProps.display ? this.alertBannerProps.display : false;
    }
}
__decorate([
    observable
], ModuleAlertBanner.prototype, "alertBannerProps", void 0);
__decorate([
    action
], ModuleAlertBanner.prototype, "updateAlertBanner", null);
__decorate([
    action
], ModuleAlertBanner.prototype, "removeStateChangeBanner", null);
__decorate([
    computed
], ModuleAlertBanner.prototype, "shouldRenderBanner", null);
export const alertBanner = new ModuleAlertBanner();
