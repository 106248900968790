import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
import style from "./ApsReportsBlock.scss";
const ApsReportsBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <agentx-wc-agent-performance-reports ?is-dark-theme=${STORE.app.darkMode}></agentx-wc-agent-performance-reports>
    `,
        styles: [
            style,
            css `
        agentx-wc-agent-performance-reports {
          width: -moz-available;
          box-sizing: border-box;
          grid-area: aps-reports;
          padding: 0 var(--lumos-spacing) var(--lumos-spacing);
        }
      `
        ]
    });
};
export default ApsReportsBlock;
