var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, computed, observable } from "mobx";
export class ModuleShortcutKeys {
    constructor() {
        this.isKeyboardShortcutsModalOpen = false;
        this.isShortcutKeyConflict = false;
    }
    /**
     * Action to update conflicts
     * @param key
     */
    updateConflictForKey(flag) {
        this.isShortcutKeyConflict = flag;
    }
    /**
     * Update Modal State Open or Close
     */
    updateShortcutKeysModal(isModalOpen) {
        this.isKeyboardShortcutsModalOpen = isModalOpen;
    }
    get shortcutKeysModal() {
        return this.isKeyboardShortcutsModalOpen;
    }
    get updateKeyboardShortcutConflict() {
        return this.isShortcutKeyConflict;
    }
}
__decorate([
    observable
], ModuleShortcutKeys.prototype, "isKeyboardShortcutsModalOpen", void 0);
__decorate([
    observable
], ModuleShortcutKeys.prototype, "isShortcutKeyConflict", void 0);
__decorate([
    action
], ModuleShortcutKeys.prototype, "updateConflictForKey", null);
__decorate([
    action
], ModuleShortcutKeys.prototype, "updateShortcutKeysModal", null);
__decorate([
    computed
], ModuleShortcutKeys.prototype, "shortcutKeysModal", null);
__decorate([
    computed
], ModuleShortcutKeys.prototype, "updateKeyboardShortcutConflict", null);
export const shortcutKeys = new ModuleShortcutKeys();
