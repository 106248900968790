import { AgentSharedActions } from "./shared-actions-agent";
import { AgentContactSharedActions } from "./shared-actions-agent-contact";
import { AuthSharedActions } from "./shared-actions-auth";
import { GeneralNotificationsSharedActions } from "./shared-actions-general-notifications";
export const initSharedStoreActions = () => {
    AgentSharedActions.Init();
    AgentContactSharedActions.Init();
    AuthSharedActions.Init();
    GeneralNotificationsSharedActions.Init();
};
