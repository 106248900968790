var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { STORE } from "@/store";
import { customElement, LitElement, property } from "lit-element";
import { html, nothing } from "lit-html";
let AgentXNotificationComp = class AgentXNotificationComp extends LitElement {
    constructor() {
        super(...arguments);
        this.notifications = STORE.generalNotifications.notifications;
        this.isNotificationsEnabled = STORE.generalNotifications.isNotificationsEnabled;
    }
    render() {
        return html `
      ${this.isNotificationsEnabled
            ? html `
            <agentx-wc-menu-notification
              .notificationsService="${this.notifications}"
              @clear-notifications="${() => {
                this.dispatchEvent(new CustomEvent("clear-notifications", {
                    detail: {},
                    bubbles: true,
                    composed: true
                }));
            }}"
            ></agentx-wc-menu-notification>
          `
            : nothing}
    `;
    }
};
__decorate([
    property({ attribute: false })
], AgentXNotificationComp.prototype, "notifications", void 0);
__decorate([
    property({ type: Boolean })
], AgentXNotificationComp.prototype, "isNotificationsEnabled", void 0);
AgentXNotificationComp = __decorate([
    customElement("agentx-notification")
], AgentXNotificationComp);
export { AgentXNotificationComp };
