import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import style from "./BannerBlock.scss";
const BannerBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- BANNER -->
      <div class="banner ${classMap({ hidden: !STORE.alertBanner.alertBannerProps.display })}">
        <md-alert-banner
          @alertBanner-hide=${() => {
            STORE.alertBanner.updateAlertBanner({
                display: false,
                id: ""
            });
            STORE.alertBanner.alertBannerProps.onHide && STORE.alertBanner.alertBannerProps.onHide();
        }}
          ?show=${STORE.alertBanner.shouldRenderBanner}
          type=${STORE.alertBanner.alertBannerProps.type ? STORE.alertBanner.alertBannerProps.type : "default"}
          ?closable=${STORE.alertBanner.alertBannerProps.closable}
          message=${STORE.alertBanner.alertBannerProps.message ? STORE.alertBanner.alertBannerProps.message : ""}
        >
          ${STORE.alertBanner.alertBannerProps.message ? STORE.alertBanner.alertBannerProps.message : ""}
        </md-alert-banner>
      </div>
    `,
        styles: [
            style,
            css `
        .banner {
          grid-area: banner; /*grid*/
          margin: 8px 0 0;
        }
      `
        ]
    });
};
export default BannerBlock;
