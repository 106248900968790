/**
 * store-common-state-variables store is used to share common variables/state accross components
 */
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createLogger } from "@/sdk";
import { action, computed, observable } from "mobx";
const logger = createLogger("[store-common-state-variables]");
export class CommonState {
    constructor() {
        this.navBarOpen = false;
        /**
         * ref CX-5228
         * 1. taskbar and incoming popover will be on top of modal overlay if CONSULT/CONFERENCE/TRANSFER model is open to prevent RONA
         * 2. task switching will be disabled
         *
         * interactionModalState is set to TRUE if interaction control modal is open
         */
        this.interactionModalState = { interactionID: null, state: false };
    }
    /**
     * Update Modal State Open or Close
     */
    updateIsNavBarOpen(navBarOpen) {
        this.navBarOpen = navBarOpen;
        logger.info("updated help content modal", this.navBarOpen);
    }
    get isNavBarOpen() {
        return this.navBarOpen;
    }
    updateInteractionModalState(interactionModalState) {
        this.interactionModalState = {
            interactionID: interactionModalState.interactionId,
            state: interactionModalState.isInteractionModelOpen
        };
        logger.info("CONSULT/CONFERENCE/TRANSFER model is open: ", this.interactionModalState);
    }
    deleteInteractionModalState() {
        this.interactionModalState = { interactionID: null, state: false };
        logger.info("CONSULT/CONFERENCE/TRANSFER model is open: ", this.interactionModalState);
    }
    get interactionModelOpenId() {
        return this.interactionModalState.interactionID;
    }
    get isInteractionModelOpen() {
        return this.interactionModalState.state;
    }
}
__decorate([
    observable
], CommonState.prototype, "navBarOpen", void 0);
__decorate([
    action
], CommonState.prototype, "updateIsNavBarOpen", null);
__decorate([
    computed
], CommonState.prototype, "isNavBarOpen", null);
__decorate([
    observable
], CommonState.prototype, "interactionModalState", void 0);
__decorate([
    action
], CommonState.prototype, "updateInteractionModalState", null);
__decorate([
    action
], CommonState.prototype, "deleteInteractionModalState", null);
__decorate([
    computed
], CommonState.prototype, "interactionModelOpenId", null);
__decorate([
    computed
], CommonState.prototype, "isInteractionModelOpen", null);
export const common = new CommonState();
