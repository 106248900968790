import EmptyBlock from "@/app/routing/shared-blocks/EmptyBlock";
import SupervisorBlock from "@/app/routing/shared-blocks/SupervisorBlock";
import { BaseView } from "@/app/routing/views/_base-view/BaseView";
import { STORE } from "@/store";
import { Router } from "@uuip/unified-ui-platform";
import style from "./HomeView.scss";
const displayRespectiveBlock = () => {
    var _a;
    if (!STORE.app.featureFlags.isSupervisorDesktopEnabled &&
        !STORE.app.featureFlags.isESDStationLoginFlowEnabled &&
        ((_a = STORE.agent.userRole) === null || _a === void 0 ? void 0 : _a.includes("supervisor"))) {
        // when login in for first time and its supervisor login
        return { empty_view: SupervisorBlock() };
    }
    return { empty_view: EmptyBlock() };
};
export const HomeView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: Object.assign({}, displayRespectiveBlock()),
        styles: style
    });
};
