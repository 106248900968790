import { interactionControl } from "./MFE/interaction-control";
import { agent } from "./store-agent";
import { agentContact } from "./store-agent-contact";
import { alertBanner } from "./store-alert-banner";
import { app } from "./store-app";
import { auth } from "./store-auth";
import { browserNotifications } from "./store-browser-notifications";
import { common } from "./store-common-state-variables";
import { layout } from "./store-desktop-layout";
import { dynamic } from "./store-dynamic";
import { envVaribles } from "./store-env-variables";
import { generalNotifications } from "./store-general-notifications";
import { page } from "./store-page";
import { routing } from "./store-routing";
import { session } from "./store-session";
import { shortcutKeys } from "./store-shortcut-key";
export const STORE = {
    agent,
    agentContact,
    app,
    auth,
    dynamic,
    routing,
    session,
    generalNotifications,
    browserNotifications,
    shortcutKeys,
    layout,
    envVaribles,
    common,
    alertBanner,
    page,
    interactionControl
};
export const updatedByFc = "FC";
// indicates the contact is updated by IMI flows
export const updatedByIMI = "IMI";
