var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { COLLAB_TOOL, PRESENCE_STAT } from "@/app/components/constants";
import { handlePresenceFailCatch, registerStateSyncManger, setPresenceServiceStatus } from "@/app/utils/StateSyncManager/UtilsSyncState";
import { logger } from "@/app/utils/Utils";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
export const handleWebexPresenceSync = () => {
    try {
        setPresenceServiceStatus(PRESENCE_STAT.SUCCESSFUL, PRESENCE_STAT.SYNC);
        logger.info(`[PresenceStateSync]: Initiating Presence State Sync Module`);
        registerStateSyncManger();
    }
    catch (error) {
        logger.error(`[Webex PresenceStateSync]: Error while registering presence sync`, error);
        handlePresenceFailCatch(error);
    }
};
export const fetchAndHandleWebexConfig = (e) => __awaiter(void 0, void 0, void 0, function* () {
    logger.info(`[Webex]: fetchAndHandleWebexConfig called`);
    const { orgId, webexConfig } = e.detail;
    // desktop profile level
    const desktopSettingWebexStateSync = (webexConfig === null || webexConfig === void 0 ? void 0 : webexConfig.stateSynchronizationWebex) && STORE.app.featureFlags.isDesktopWebexStateSyncEnabled;
    const desktopSettingWebexUserDetaisEnabled = (webexConfig === null || webexConfig === void 0 ? void 0 : webexConfig.showUserDetailsWebex) && STORE.app.featureFlags.isWebexDirectoryViewEnabled;
    if (desktopSettingWebexStateSync || desktopSettingWebexUserDetaisEnabled) {
        // Initiate the webex api config to get the Tenant level settings
        const response = yield SERVICE.cms.fetchWebexConfigData({
            orgId
        });
        logger.info(`[Webex]: fetchWebexConfigData response:`, response);
        if (response && response.length > 0) {
            // tenant level
            const webexResponse = response[0];
            const displayWebexUserDetailsEnabled = desktopSettingWebexUserDetaisEnabled &&
                (webexResponse === null || webexResponse === void 0 ? void 0 : webexResponse.showUserDetails) &&
                STORE.app.featureFlags.isWebexDirectoryViewEnabled;
            STORE.agent.updateDisplayWebexUserDetailsEnabled(displayWebexUserDetailsEnabled);
            const stateSyncEnabled = desktopSettingWebexStateSync &&
                (webexResponse === null || webexResponse === void 0 ? void 0 : webexResponse.stateSynchronization) &&
                STORE.app.featureFlags.isDesktopWebexStateSyncEnabled;
            // store the desktop + tenant level combinaison result.
            logger.info(`[Webex] actual client configuration showUserDetails:${displayWebexUserDetailsEnabled} stateSynchronization:${stateSyncEnabled}`);
            STORE.app.updateWebexOrgConfig({
                showUserDetails: displayWebexUserDetailsEnabled,
                stateSynchronization: stateSyncEnabled,
                idleCodes: webexResponse.idleCodes
            });
            if (stateSyncEnabled) {
                STORE.agent.updateStateSyncEnabled(true);
                STORE.agent.updateCollabToolInSync(COLLAB_TOOL.WEBEX);
                handleWebexPresenceSync();
            }
            if (displayWebexUserDetailsEnabled) {
                SERVICE.webex.registerToDSS();
                SERVICE.webex.initialisePresencePlugin();
            }
        }
    }
    else {
        logger.info("[Webex]: stateSynchronizationWebex is disabled, Webex Config Api is not called");
    }
});
