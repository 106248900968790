var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createLogger } from "@/sdk";
import { action, computed, observable, toJS } from "mobx";
export default class ModuleEmail {
    constructor() {
        this.logger = createLogger("[store-email] [EmailComposer]");
        this._template = {
            defaultValues: {
                defaultTypeAgent: "",
                defaultTypeForAgent: false,
                defaultTypeCustomer: "",
                defaultTypeForCustomer: false
            },
            emailTemplate: ""
        };
        this._emailCache = new Map();
        /**
         * Actions
         */
        this.setTemplate = (e) => {
            this._template = e.detail.template;
        };
        this.updateDraftEmailContent = (e) => {
            this.logger.info("email details setting in cache");
            this._emailCache.set(e.detail.taskId, e.detail);
        };
        this.deleteEmailFromCache = (interactionId) => {
            this._emailCache.delete(interactionId);
        };
    }
    /**
     * Computed
     */
    get templateSelector() {
        return this._template;
    }
    get emailCacheSelector() {
        this.logger.info("email content getting from cache");
        return toJS(this._emailCache);
    }
}
__decorate([
    observable
], ModuleEmail.prototype, "_template", void 0);
__decorate([
    observable
], ModuleEmail.prototype, "_emailCache", void 0);
__decorate([
    computed
], ModuleEmail.prototype, "templateSelector", null);
__decorate([
    computed
], ModuleEmail.prototype, "emailCacheSelector", null);
__decorate([
    action
], ModuleEmail.prototype, "setTemplate", void 0);
__decorate([
    action
], ModuleEmail.prototype, "updateDraftEmailContent", void 0);
__decorate([
    action
], ModuleEmail.prototype, "deleteEmailFromCache", void 0);
