var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { COLLAB_TOOL, PRESENCE_STAT } from "@/app/components/constants";
import { handlePresenceFailCatch, registerStateSyncManger, setPresenceServiceStatus } from "@/app/utils/StateSyncManager/UtilsSyncState";
import { t } from "@/mixins/i18nMixin";
import { createLogger } from "@/sdk";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { ToasterNotificationItemContent } from "@uuip/unified-ui-platform-common-components";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
export const logger = createLogger("[Microsoft Integration]");
const clientId = process.env.MICROSOFT_CLIENT_ID;
const initializeEventCallback = () => {
    var _a;
    const MSAuthEventInstance = (_a = SERVICE.microsoft) === null || _a === void 0 ? void 0 : _a.getEventInstance();
    MSAuthEventInstance === null || MSAuthEventInstance === void 0 ? void 0 : MSAuthEventInstance.listen((event) => {
        if (event.eventType === SERVICE.constants.MicrosoftServiceEvents.RENEW_ACCESS_TOKEN_FAILURE) {
            // If the token renewal using Jabber Service fails intermittently, we will show the error toaster notification to the user.
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            triggerErrorNotification(retryAuthentication);
            STORE.auth.updateMicrosoftAuthServiceAuthenticated(false);
        }
    });
};
export const initializeMicrosoftAuthService = (clientId, tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        yield ((_a = SERVICE.microsoft) === null || _a === void 0 ? void 0 : _a.initialize(clientId, tenantId));
        if (STORE.app.featureFlags.isDesktopMSStateSyncEnabled) {
            // This is to listen the token renewal failure event.
            // If the token renewal using Jabber Service fails intermittently, we will show the error toaster notification to the user.
            initializeEventCallback();
        }
    }
    catch (error) {
        logger.error("[Microsoft Auth]: Error on initializing Microsoft Service", error);
        throw error;
    }
});
export const triggerMicrosoftLogin = () => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    try {
        yield ((_b = SERVICE.microsoft) === null || _b === void 0 ? void 0 : _b.initiateMicrosoftLogin());
        STORE.auth.updateMicrosoftAuthServiceAuthenticated(true);
    }
    catch (error) {
        logger.error("[Microsoft Auth]: Error on triggering microsoft login", error);
        // Set error on store.
        STORE.auth.updateMicrosoftAuthServiceAuthenticated(false);
        throw error;
    }
});
export const triggerErrorNotification = (retryCallback) => {
    const notificationDataError = {
        type: Notifications.ItemMeta.Type.Error,
        mode: Notifications.ItemMeta.Mode.AutoDismiss,
        title: t("app:microsoft.authencationFailedTitle"),
        data: new ToasterNotificationItemContent.DataController({
            text: t("app:microsoft.authencationFailedSubtitle"),
            linkName: t("app:microsoft.tryAgain"),
            linkHandler: () => {
                if (retryCallback) {
                    retryCallback();
                }
            }
        })
    };
    STORE.generalNotifications.fireNotificationEvent(notificationDataError);
};
export const handlePresenceSync = () => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    try {
        yield ((_c = SERVICE.microsoft) === null || _c === void 0 ? void 0 : _c.presenceAPI());
        logger.info(`[Microsoft PresenceStateSync]: Presence sync api Sucessful`);
        setPresenceServiceStatus(PRESENCE_STAT.SUCCESSFUL, PRESENCE_STAT.SYNC);
        logger.info("[PresenceStateSync]: Initiating Presence State Sync Module.");
        registerStateSyncManger();
    }
    catch (error) {
        logger.error(`[Microsoft PresenceStateSync]: Error while registering presence sync`, error);
        handlePresenceFailCatch(error);
    }
});
export const initializePresenceSync = () => __awaiter(void 0, void 0, void 0, function* () {
    var _d, _e;
    if (STORE.agent.collaboration.stateSynchronization) {
        if ((_d = SERVICE.webex) === null || _d === void 0 ? void 0 : _d.webexInitializedStatus()) {
            yield handlePresenceSync();
        }
        else {
            yield ((_e = SERVICE.webex) === null || _e === void 0 ? void 0 : _e.registerWebex(STORE.app.isEnvironmentProd)); // handle msft login triggers state sync before station login success.
            yield handlePresenceSync();
        }
    }
});
export const triggerMicrosoftAuth = (clientId, tenantId) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    logger.info("[Microsoft Auth]: Triggering Microsoft Auth Flow");
    try {
        yield initializeMicrosoftAuthService(clientId, tenantId);
        yield triggerMicrosoftLogin();
        logger.info("[Microsoft Auth]: Microsoft Authentication Successful");
        (_f = SERVICE.microsoft) === null || _f === void 0 ? void 0 : _f.initializeGraphClient();
        initializePresenceSync();
    }
    catch (error) {
        logger.error("[Microsoft Auth]: Triggering Microsoft Auth Flow Failed");
        /* eslint-disable  @typescript-eslint/no-use-before-define */
        triggerErrorNotification(retryAuthentication);
    }
});
export const retryAuthentication = () => __awaiter(void 0, void 0, void 0, function* () {
    var _g, _h, _j, _k, _l;
    logger.info("[Microsoft Auth]: Retrying Microsoft Authentication");
    try {
        if (STORE.app.microsoftOrgConfig && !((_g = SERVICE.microsoft) === null || _g === void 0 ? void 0 : _g.isInitialized)) {
            // Microsoft App Initialization was failed.
            // Re-Trigger the microsoft auth service.
            triggerMicrosoftAuth(clientId, (_j = (_h = STORE.app.microsoftOrgConfig) === null || _h === void 0 ? void 0 : _h.accountDetails) === null || _j === void 0 ? void 0 : _j.tenantId);
        }
        else {
            // Re-Trigger the microsoft login flow.
            yield ((_k = SERVICE.microsoft) === null || _k === void 0 ? void 0 : _k.loginPopup());
            logger.info("[Microsoft Auth]: Retrying Microsoft Authentication Successful");
            (_l = SERVICE.microsoft) === null || _l === void 0 ? void 0 : _l.initializeGraphClient();
            STORE.auth.updateMicrosoftAuthServiceAuthenticated(true);
            initializePresenceSync();
        }
    }
    catch (error) {
        logger.error("[Microsoft Auth]: Retrying Microsoft Authentication Failed", error);
        STORE.auth.updateMicrosoftAuthServiceAuthenticated(false);
        triggerErrorNotification(retryAuthentication);
    }
});
export const handleMicrosoftAuthFlow = (e) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { orgId, microsoftConfig } = e.detail;
        const response = yield SERVICE.cms.fetchMicrosoftConfig({
            orgId
        });
        logger.info(`[Microsoft Auth]: fetchMicrosoftOrgConfigApi response:`, response);
        const { data } = response;
        let msResponse;
        // Check Microsoft Feature is enabled or not.
        if (data && Array.isArray(data) && data.length > 0) {
            msResponse = data[0];
            STORE.app.updateMicrosoftOrgConfig(msResponse);
            if ((msResponse === null || msResponse === void 0 ? void 0 : msResponse.active) && ((msResponse === null || msResponse === void 0 ? void 0 : msResponse.showUserDetails) || (msResponse === null || msResponse === void 0 ? void 0 : msResponse.stateSynchronization))) {
                // Set Microsoft Auth Service is Enabled in Tenant Level
                STORE.auth.updateMicrosoftAuthServiceEnabled(true);
                // Update the User Details is Enabled or not.
                const displayMicrosoftUserDetails = msResponse.showUserDetails && (microsoftConfig === null || microsoftConfig === void 0 ? void 0 : microsoftConfig.showUserDetailsMS);
                STORE.agent.updateDisplayMicrosoftUserDetailsEnabled(displayMicrosoftUserDetails !== null && displayMicrosoftUserDetails !== void 0 ? displayMicrosoftUserDetails : false);
                // Update the State Synchronization is Enabled or not.
                const stateSynchronization = STORE.app.featureFlags.isDesktopMSStateSyncEnabled &&
                    msResponse.stateSynchronization &&
                    (microsoftConfig === null || microsoftConfig === void 0 ? void 0 : microsoftConfig.stateSynchronizationMS);
                STORE.agent.updateStateSyncEnabled(stateSynchronization !== null && stateSynchronization !== void 0 ? stateSynchronization : false);
                STORE.agent.updateCollabToolInSync(COLLAB_TOOL.TEAMS);
                // Trigger the Microsoft Login Flow
                triggerMicrosoftAuth(clientId, msResponse.accountDetails.tenantId); // Have to check this await is required or not.
            }
            else {
                // Set Microsoft Auth Service is Disabled in Tenant Level
                STORE.auth.updateMicrosoftAuthServiceEnabled(false);
            }
        }
    }
    catch (error) {
        logger.error(`[Microsoft Auth]: Error on fetching microsoft tenant configuration`, error);
    }
});
