var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import webexImg from "@img/teams-icon-color.svg";
import { css, customElement, LitElement, property } from "lit-element";
import { html, nothing } from "lit-html";
import { handleShortcutKeyEvent, handleWebexModal } from "../../utils/Utils";
let AgentXWebexComp = class AgentXWebexComp extends LitElement {
    constructor() {
        super(...arguments);
        this.webexNotificationCount = STORE.generalNotifications.webexNotificationCount;
        this.webexEnabled = STORE.app.webexEnabled;
    }
    connectedCallback() {
        super.connectedCallback();
        this.stopListen = handleShortcutKeyEvent();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        if (this.stopListen) {
            this.stopListen();
        }
    }
    render() {
        return html `
      <span class="webex-notification-icon_wrap">
        <md-tooltip message=${t("app:common.webex")} placement="bottom">
          <md-button
            class="webex-opener"
            size="40"
            circle
            ariaLabel=${t("app:common.webexAriaLable", "Webex", { count: this.webexNotificationCount })}
            circle
            variant="secondary"
            @click=${() => {
            handleWebexModal();
        }}
          >
            <img class="webex-img" alt=${t("app:common.webex")} src=${webexImg} />
          </md-button>
        </md-tooltip>
        ${this.webexNotificationCount > 0
            ? html `
              <md-badge class="webex-notification-icon-badge" color="darkred" small>
                <span>${this.webexNotificationCount}</span>
              </md-badge>
            `
            : nothing}
      </span>
    `;
    }
};
AgentXWebexComp.styles = css `
    .webex-opener::part(button) {
      background-color: rgba(0, 0, 0, 0);
    }

    .webex-opener::part(button):active {
      background-color: var(--button-white-pressed-bg-color);
    }

    .webex-opener::part(button):hover {
      background-color: var(--button-white-hover-bg-color);
    }

    .webex-img {
      height: calc(var(--space-token-even) * 8);
      width: calc(var(--space-token-even) * 10);
    }

    .webex-notification-icon_wrap {
      display: inline-block;
      position: relative;
    }

    .webex-notification-icon_wrap md-badge::part(badge) {
      align-self: center;
      color: var(--color-count-notifications);
      display: inline-flex;
      font-size: var(--font-size-small);
      height: 18px;
      justify-content: center;
      min-width: 18px;
      padding: 0 0.3rem;
    }

    .webex-notification-icon-badge {
      left: 60%;
      position: absolute;
      top: 0;
      transform: translate(-20%, -10%);
    }
  `;
__decorate([
    property({ type: Number })
], AgentXWebexComp.prototype, "webexNotificationCount", void 0);
__decorate([
    property({ type: Boolean })
], AgentXWebexComp.prototype, "webexEnabled", void 0);
AgentXWebexComp = __decorate([
    customElement("agentx-webex")
], AgentXWebexComp);
export { AgentXWebexComp };
