var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import "@agentx/agentx-mfe-wc-based";
import { customElement, html, LitElement } from "lit-element";
import style from "./EmptyResponsiveWidget.scss";
let EmptyResponsiveWidget = class EmptyResponsiveWidget extends LitElement {
    static get styles() {
        return style;
    }
    render() {
        return html `
      <agentx-wc-empty-state-wrapper illustration-type="empty-state">
        <span class="no-widgets-message">${t("app:desktopLayout.respNoWidget")}</span>
      </agentx-wc-empty-state-wrapper>
    `;
    }
};
EmptyResponsiveWidget = __decorate([
    customElement("empty-responsive-widget")
], EmptyResponsiveWidget);
export { EmptyResponsiveWidget };
