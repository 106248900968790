import { createLogger } from "@/sdk";
import { SERVICE } from "@agentx/agentx-services";
import { Notifications } from "@uuip/unified-ui-platform-sdk";
import { generalNotifications } from "../store-general-notifications";
const logger = createLogger("[SharedActions]");
export var GeneralNotificationsSharedActions;
(function (GeneralNotificationsSharedActions) {
    GeneralNotificationsSharedActions.Init = () => {
        // Bind general notifications store actions channels
        // Fire Acknowledge Dialog
        {
            const d = SERVICE.actionsChannels.createDestination("fireGeneralAcknowledgeNotification/Req");
            const s = SERVICE.actionsChannels.createSource("fireGeneralAcknowledgeNotification/Res");
            /* istanbul ignore next */
            d.addListener(({ meta, args: [raw, req_id] }) => {
                if (raw.data.mode !== Notifications.ItemMeta.Mode.Acknowledge) {
                    logger.warn(`"fireGeneralAcknowledgeNotification/Req" supports only Acknowledge notification type.` +
                        `Received from "${meta.sourceTag}" mode "${raw.data.mode}". Ignoring`);
                    return;
                }
                const fired = generalNotifications.fire(raw);
                if (fired) {
                    const [n] = fired;
                    if (generalNotifications.isSilentNotificationsEnabled) {
                        s.send(n.status, n.reason, n.mode);
                        return;
                    }
                    const statusUpdateListener = (status, reason) => {
                        if (status === Notifications.ItemMeta.Status.Deactivated) {
                            n.removeEventListener("statusUpdate", statusUpdateListener);
                        }
                        s.send(status, reason, n.mode, req_id);
                    };
                    n.addEventListener("statusUpdate", statusUpdateListener);
                }
            });
        }
        // Fire Autodismiss
        {
            const d = SERVICE.actionsChannels.createDestination("fireGeneralAutoDismissNotification/Req");
            const s = SERVICE.actionsChannels.createSource("fireGeneralAutoDismissNotification/Res");
            /* istanbul ignore next */
            d.addListener(({ meta, args: [raw, req_id] }) => {
                if (raw.data.mode !== Notifications.ItemMeta.Mode.AutoDismiss) {
                    logger.warn(`"fireGeneralAutoDismissNotification/Req" supports only AutoDismiss notification type.` +
                        `Received from "${meta.sourceTag}" mode "${raw.data.mode}". Ignoring`);
                    return;
                }
                const fired = generalNotifications.fire(raw);
                if (fired) {
                    const [n] = fired;
                    if (generalNotifications.isSilentNotificationsEnabled) {
                        s.send(n.status, n.reason, n.mode);
                        return;
                    }
                    const statusUpdateListener = (status, reason) => {
                        if (status === Notifications.ItemMeta.Status.Pended &&
                            reason !== Notifications.ItemMeta.PendingEventReason.ServiceAutoPropagate) {
                            n.deactivate(Notifications.ItemMeta.DeactivateEventReason.UserNeutral);
                        }
                        if (status === Notifications.ItemMeta.Status.Deactivated) {
                            n.removeEventListener("statusUpdate", statusUpdateListener);
                        }
                        s.send(status, reason, n.mode, req_id);
                    };
                    n.addEventListener("statusUpdate", statusUpdateListener);
                }
            });
        }
        // Fire Silent
        {
            const d = SERVICE.actionsChannels.createDestination("fireGeneralSilentNotification/Req");
            /* istanbul ignore next */
            d.addListener(({ meta, args: [raw] }) => {
                if (raw.data.mode !== Notifications.ItemMeta.Mode.Silent) {
                    logger.warn(`"fireGeneralSilentNotification/Req" supports only Silent notification type.` +
                        `Received from "${meta.sourceTag}" mode "${raw.data.mode}". Ignoring`);
                    return;
                }
                generalNotifications.fire(raw);
            });
        }
    };
})(GeneralNotificationsSharedActions || (GeneralNotificationsSharedActions = {}));
