var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import "@agentx/agentx-mfe-wc-based";
import "@uuip/unified-ui-platform";
import { customElement, html, internalProperty, LitElement, property, query } from "lit-element";
import { UTILS_DYNAMIC } from "../utils/UtilsDynamic";
const SECTION_MAX_WIDTH = 240;
import style from "./TimezoneLabel.scss";
/**
 * @element timezone-label
 */
let TimezoneLabel = class TimezoneLabel extends LitElement {
    constructor() {
        super(...arguments);
        this.tenantTimezone = "";
        this.isOverflowing = false;
        this.timezoneValueWidth = 120;
        this.timezoneStoreValue = "";
    }
    static get styles() {
        return style;
    }
    updated(changedProperties) {
        var _a;
        super.updated(changedProperties);
        const { clientWidth, scrollWidth } = this.timezoneValue;
        if (clientWidth < scrollWidth) {
            this.isOverflowing = true;
        }
        else {
            this.isOverflowing = false;
        }
        this.timezoneValueWidth = SECTION_MAX_WIDTH - ((_a = this.timezoneLabel) === null || _a === void 0 ? void 0 : _a.clientWidth);
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    firstUpdated(_changedProperties) {
        super.firstUpdated(_changedProperties);
        const upd = (v) => (this.tenantTimezone = v);
        const { value } = UTILS_DYNAMIC.storeDataProvider(this.tenantTimezone, upd);
        this.timezoneStoreValue = value;
    }
    render() {
        return html `
      <div class="timezone-container" style="max-width: ${SECTION_MAX_WIDTH}px;">
        <p class="title">${t("app:common.timeZone")} :</p>
        &nbsp;
        <md-tooltip
          message="${this.timezoneStoreValue === ""
            ? t("app:timezoneLabel.notDefinedTooltip")
            : this.timezoneStoreValue}"
          ?disabled=${this.timezoneStoreValue === "" ? false : !this.isOverflowing}
          placement="bottom"
        >
          <p style="max-width: ${this.timezoneValueWidth}px" class="timezone">
            ${this.timezoneStoreValue === "" ? t("app:timezoneLabel.notDefined") : this.timezoneStoreValue}
          </p>
        </md-tooltip>
      </div>
    `;
    }
};
__decorate([
    property()
], TimezoneLabel.prototype, "tenantTimezone", void 0);
__decorate([
    internalProperty()
], TimezoneLabel.prototype, "isOverflowing", void 0);
__decorate([
    internalProperty()
], TimezoneLabel.prototype, "timezoneValueWidth", void 0);
__decorate([
    internalProperty()
], TimezoneLabel.prototype, "timezoneStoreValue", void 0);
__decorate([
    query("p.timezone")
], TimezoneLabel.prototype, "timezoneValue", void 0);
__decorate([
    query("p.title")
], TimezoneLabel.prototype, "timezoneLabel", void 0);
TimezoneLabel = __decorate([
    customElement("timezone-label")
], TimezoneLabel);
export { TimezoneLabel };
