/******************************************************
 * This store is dedicated to keeping MFE specific data
 * that has to persist beyond MFE lifecycle
 * Includding:
 * - Predefined Chat Responses (pulled once by Chat, stored here througout the session)
 * - Email message templates (pulled once EmailComposer, stored here througout the session)
 * - Email draf messages
 * - Auto Wrapup details for Interactions
 ******************************************************/
import InteractionControl from "./MFE/interaction-control";
import ModuleIvrTranscript from "./MFE/ivr-transcript";
import { ModuleChat } from "./MFE/module-chat";
import ModuleEmail from "./MFE/module-email";
import ModuleErrorDialog from "./MFE/module-error";
import ModuleScreenPop from "./MFE/screen-pop";
export class ModuleSession {
    constructor() {
        this.email = new ModuleEmail();
        this.chat = new ModuleChat();
        this.screenpop = new ModuleScreenPop();
        this.errorModal = new ModuleErrorDialog();
        this.interactionControl = new InteractionControl();
        this.ivrTransctipt = new ModuleIvrTranscript();
    }
}
export const session = new ModuleSession();
