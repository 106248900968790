import { STORE } from "@/store";
import { fetchManagedQueues, fetchManagedTeams } from "./Utils";
const updateSelectedChannelList = (e) => {
    STORE.agent.updateSelectedChannelList(e.detail);
};
const updateSelectedTeamList = (e) => {
    STORE.agent.updateSelectedTeamList(e.detail);
};
const updateSelectedQueueList = (e) => {
    STORE.agent.updateSelectedQueueList(e.detail);
};
const fetchManagedTeamsUtil = () => {
    fetchManagedTeams();
};
const fetchManagedQueuesUtil = () => {
    fetchManagedQueues();
};
const updateOrgIdleCodesUtil = (e) => {
    STORE.agent.updateOrganizationIdleCodes(e.detail.isFetchingSuccess, e.detail.response);
};
export const attachDynamicLayoutListeners = () => {
    window.addEventListener("channel-filter-selected", updateSelectedChannelList);
    window.addEventListener("team-filter-selected", updateSelectedTeamList);
    window.addEventListener("queue-filter-selected", updateSelectedQueueList);
    window.addEventListener("fetch-managed-team", fetchManagedTeamsUtil);
    window.addEventListener("fetch-managed-queue", fetchManagedQueuesUtil);
    window.addEventListener("update-org-idle-codes", updateOrgIdleCodesUtil);
};
export const removeDynamicLayoutListeners = () => {
    window.removeEventListener("channel-filter-selected", updateSelectedChannelList);
    window.removeEventListener("team-filter-selected", updateSelectedTeamList);
    window.removeEventListener("queue-filter-selected", updateSelectedQueueList);
    window.removeEventListener("fetch-managed-team", fetchManagedTeamsUtil);
    window.removeEventListener("fetch-managed-queue", fetchManagedQueuesUtil);
    window.removeEventListener("update-org-idle-codes", updateOrgIdleCodesUtil);
};
