var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createLogger } from "@/sdk";
import { action, observable } from "mobx";
const logger = createLogger("[store-ivr-transcript]");
export default class ModuleIvrTranscript {
    constructor() {
        this.ivrTranscriptMap = new Map();
    }
    saveTranscript(interactionID, ivrConversations) {
        logger.info(`event=saveTranscript | interactionID=${interactionID} | [IVR transcript] fetched, Saving data in store`);
        this.ivrTranscriptMap.set(interactionID, ivrConversations);
    }
    deleteTranscript(interactionID) {
        if (this.ivrTranscriptMap.get(interactionID)) {
            this.ivrTranscriptMap.delete(interactionID);
        }
    }
}
__decorate([
    observable
], ModuleIvrTranscript.prototype, "ivrTranscriptMap", void 0);
__decorate([
    action
], ModuleIvrTranscript.prototype, "saveTranscript", null);
__decorate([
    action
], ModuleIvrTranscript.prototype, "deleteTranscript", null);
