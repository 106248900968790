var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { isSupervisor } from "@/app/utils/Utils";
import { STORE } from "@/store";
import { LitElement, customElement, property } from "lit-element";
import { html, nothing } from "lit-html";
import { hideBanner, showBanner } from "../../utils/AlertBannerUtils";
import { AGENT_STATE_CHANGEDBY_NAME } from "../constants";
let AgentXStateSelectorComp = class AgentXStateSelectorComp extends LitElement {
    constructor() {
        super(...arguments);
        this.agentStateProps = STORE.agent.getAgentStateProps;
    }
    connectedCallback() {
        super.connectedCallback();
        setTimeout(() => this.requestUpdate(), 0);
    }
    render() {
        return !isSupervisor()
            ? html `
          <agentx-react-state-selector
            .agentStateProps="${this.agentStateProps}"
            @ax-idle-codes-update="${(e) => STORE.agent.updateAgentStateIdleCodes(e.detail)}"
            @ax-state-selected="${(e) => STORE.agent.selectState(e)}"
            @ax-banner-show="${(e) => showBanner(e)}"
            @ax-banner-hide="${() => hideBanner(AGENT_STATE_CHANGEDBY_NAME)}"
            @ax-state-selected-failed="${(e) => STORE.session.errorModal.setErrorDetails(e)}"
          ></agentx-react-state-selector>
        `
            : nothing;
    }
};
__decorate([
    property({ attribute: false })
], AgentXStateSelectorComp.prototype, "agentStateProps", void 0);
AgentXStateSelectorComp = __decorate([
    customElement("agentx-state-selector")
], AgentXStateSelectorComp);
export { AgentXStateSelectorComp };
