/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint "no-console": "off"*/
import { SERVICE } from "@agentx/agentx-services";
// Make available global variables for third-party
const exposeAppGlobalVariables = () => {
    /**
     * WARNING! Providing temporary as global for Desktop(wxcc-desktop/sdk) widgets support
     * global READONLY
     */
    Object.defineProperty(global, "AGENTX_SERVICE", {
        writable: false,
        value: new Proxy(SERVICE, {
            // Preventing any property override via proxy
            set(_SERVICE, prop, val) {
                console.error("You are not allowed to override AGENTX_SERVICE global object properties");
                return false;
            },
            // Preventing any property delete via proxy
            deleteProperty(_SERVICE, prop) {
                console.error("You are not allowed to delete AGENTX_SERVICE global object properties");
                return false;
            }
        })
    });
};
exposeAppGlobalVariables();
