import { UTILS_DYNAMIC } from "@/app/utils/UtilsDynamic";
import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html, nothing } from "lit-html";
import style from "./PersistentAreaBlock.scss";
const PersistentAreaBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <div class="persistent-area">
        ${STORE.dynamic.showPersistentWidgets
            ? html `
              <uuip-dynamic-widget
                .options=${STORE.dynamic.persistentWidgets}
                .dataProvider=${UTILS_DYNAMIC.storeDataProvider}
              >
              </uuip-dynamic-widget>
            `
            : nothing}
      </div>
    `,
        styles: STORE.dynamic.showPersistentWidgets ? style : css ``
    });
};
export default PersistentAreaBlock;
