import { STORE } from "@/store";
import "@momentum-ui/web-components";
import { Router } from "@uuip/unified-ui-platform";
import { html, nothing } from "lit-html";
import { BaseView } from "../_base-view/BaseView";
import style from "./DefaultLandingView.scss";
const DefaultLandingView = () => {
    return new Router.RouterView.BlocksMap(BaseView(), {
        blocks: {
            empty_view: new Router.RouterView.Block({
                template: html `
          <div class="supervisor-home-wrapper">
            <div class="loader-block">
              ${!STORE.app.isStationLoginSuccess
                    ? html `
                    <div className="loading-spinner-div">
                      <md-spinner size="60"></md-spinner>
                    </div>
                  `
                    : nothing}
            </div>
          </div>
        `,
                styles: style
            })
        }
    });
};
export default DefaultLandingView;
