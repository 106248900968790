import { SERVICE } from "@agentx/agentx-services";
import { toJS } from "mobx";
import { agent } from "../store-agent";
export var AgentSharedActions;
(function (AgentSharedActions) {
    AgentSharedActions.Init = () => {
        // Bind agent store actions channels
        // GetIdleCodes
        {
            const d = SERVICE.actionsChannels.createDestination("getIdleCodes/Req");
            const s = SERVICE.actionsChannels.createSource("getIdleCodes/Res");
            /* istanbul ignore next */
            d.addListener(({ args: [req_uuid] }) => s.send(
            //Copying to prevent mutation
            toJS(agent.idleCodes), req_uuid));
        }
        // GetWrapUpCodes
        {
            const d = SERVICE.actionsChannels.createDestination("getWrapUpCodes/Req");
            const s = SERVICE.actionsChannels.createSource("getWrapUpCodes/Res");
            /* istanbul ignore next */
            d.addListener(({ args: [req_uuid] }) => s.send(
            //Copying to prevent mutation
            toJS(agent.wrapUpData.wrapUpProps.wrapUpReasonList), req_uuid));
        }
        // GetState => in agentx-js-api => agentInfo => latestData
        // GetSubState => in agentx-js-api => agentInfo => latestData
    };
})(AgentSharedActions || (AgentSharedActions = {}));
