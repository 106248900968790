var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RouteName } from "@/app/routing/routing";
import { STORE } from "@/store";
import { MediaType } from "@/store/store-agent-contact";
import { SERVICE } from "@agentx/agentx-services";
import { action, computed, observable, toJS } from "mobx";
import { getMediaChannelForNotification, isMediaTypeDigitalOutbound } from "@/app/utils/Utils";
const UNREAD_CHAT_MESSAGE_EVENT = "unread-chat-message";
export class ModuleChat {
    constructor() {
        this.chatMessage = [];
        this.mediaViewInitState = { state: "LOADING" };
        this.retryMediaViewInit = 0;
        this.removeMediaListener = false;
        this.hasCustomerLeft = new Map();
        this.isConvNotFound = new Map();
        this.unreadMessages = new Map();
        this.imiTeamId = "719";
        this.imiAgentId = "narender";
        this.imiConvId = "CSROEQSWI5OTLU2C";
        this.imiWidgetInitState = true;
        this.imiServicesInitialized = false;
        this.newUnreadTaskMessage = {};
    }
    get unReadMessagesObject() {
        return toJS(this.unreadMessages);
    }
    get getNewUnreadMessageId() {
        return toJS(this.newUnreadTaskMessage);
    }
    get mediaViewOption() {
        return {
            accessToken: STORE.auth.accessToken,
            refreshToken: STORE.auth.refreshToken,
            clientId: STORE.auth.authService.config.clientId,
            clientSecret: STORE.auth.authService.config.clientSecret,
            retryMediaViewInit: this.retryMediaViewInit,
            removeMediaListener: this.removeMediaListener,
            isNativeChat: STORE.app.featureFlags.isNativeChat
        };
    }
    setChatMessages(chatMessages) {
        this.chatMessage = chatMessages;
    }
    isChatConvActive(convId) {
        if (STORE.routing.currentRouteName === RouteName.TASKPANEL) {
            const selectedTask = STORE.agentContact.taskMap.get(STORE.agentContact.selectedTaskId);
            if (selectedTask &&
                (selectedTask.interaction.mediaType === MediaType.Chat ||
                    selectedTask.interaction.mediaType === MediaType.Social)) {
                const mediaResourceId = selectedTask.isConsulted
                    ? selectedTask.consultMediaResourceId
                    : selectedTask.mediaResourceId;
                if (!(mediaResourceId && mediaResourceId.includes(convId))) {
                    // Condition 1: selected task mediaResourceId and message event media resource id are not same
                    return false;
                }
            }
            else {
                // Condition 2: If selected task is not chat or social type
                return false;
            }
        }
        else {
            // Condition 3: If Agent is not in Task Route
            return false;
        }
        return true;
    }
    setUnreadMessages(data) {
        const task = STORE.agentContact.getTaskIdFromConvId(data.convId);
        const taskId = (task === null || task === void 0 ? void 0 : task.interactionId) || "";
        const savedUnreadMessages = this.unreadMessages.get(taskId) || {};
        if (taskId !== "" && !this.isChatConvActive(data.convId)) {
            savedUnreadMessages.unreadMessageCount = savedUnreadMessages.unreadMessageCount
                ? savedUnreadMessages.unreadMessageCount + 1
                : 1;
            savedUnreadMessages.lastUnreadMessage = data.message;
            this.unreadMessages.set(taskId, savedUnreadMessages);
            if (task) {
                this.fireChatMessageNotification(data, task);
                this.newUnreadTaskMessage = toJS({
                    interactionId: task.interactionId,
                    unreadMessageCount: savedUnreadMessages.unreadMessageCount
                });
            }
            sessionStorage.setItem(UNREAD_CHAT_MESSAGE_EVENT, JSON.stringify(toJS(this.unreadMessages)));
        }
    }
    fireChatMessageNotification(data, task) {
        const mediaChannel = task.interaction.mediaChannel;
        const mediaType = task.interaction.mediaType;
        const taskId = task.interactionId;
        const chatId = task.mediaResourceId || task.consultMediaResourceId;
        const direction = task.interaction.contactDirection.type;
        let iconUrl = undefined;
        if (!task.interaction.media[chatId].mediaMgr.includes("digitalmm") &&
            mediaType === MediaType.Social &&
            data.actorType === "ROBOT") {
            data.actorName =
                task.interaction.callProcessingDetails.customerName || task.interaction.callProcessingDetails.appUser;
        }
        const derivedMediaChannel = getMediaChannelForNotification(mediaChannel, direction);
        if (this.getMediaSubChannelType(mediaType, derivedMediaChannel)) {
            iconUrl = this.getNotificationIconUrl(derivedMediaChannel);
        }
        STORE.generalNotifications.fireNewChatMessage(data, mediaChannel && mediaType === MediaType.Social
            ? mediaChannel === "facebook"
                ? "messenger"
                : mediaChannel
            : MediaType.Chat, iconUrl, taskId);
    }
    getMediaSubChannelType(mediaType, mediaChannel) {
        return (mediaType === MediaType.Social || isMediaTypeDigitalOutbound(mediaChannel)) &&
            mediaChannel &&
            SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[mediaChannel]
            ? true
            : false;
    }
    getNotificationIconUrl(mediaChannel) {
        var _a, _b, _c, _d;
        return STORE.app.darkMode
            ? (_b = (_a = SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[mediaChannel]) === null || _a === void 0 ? void 0 : _a.notification) === null || _b === void 0 ? void 0 : _b.dark
            : (_d = (_c = SERVICE.constants.MEDIA_SUBCHANNEL_TYPES[mediaChannel]) === null || _c === void 0 ? void 0 : _c.notification) === null || _d === void 0 ? void 0 : _d.light;
    }
    getUnreadMessagesFromSessionStorage(data) {
        if (data) {
            for (const [taskId, obj] of Object.entries(data)) {
                this.unreadMessages.set(taskId, obj);
            }
        }
    }
    updateRemoveMediaListeners(status) {
        this.removeMediaListener = status;
    }
    updateHasCustomerLeft(convId) {
        var _a;
        const taskId = ((_a = STORE.agentContact.getTaskIdFromConvId(convId)) === null || _a === void 0 ? void 0 : _a.interactionId) || "";
        if (taskId !== "") {
            this.hasCustomerLeft.set(taskId, true);
        }
    }
    updateIsConvNotFound(convId, status) {
        var _a;
        const taskId = ((_a = STORE.agentContact.getTaskIdFromConvId(convId)) === null || _a === void 0 ? void 0 : _a.interactionId) || "";
        if (taskId !== "") {
            this.isConvNotFound.set(taskId, status);
        }
    }
    deleteUnreadMessages(taskId) {
        if (taskId !== "") {
            this.unreadMessages.delete(taskId);
            sessionStorage.setItem(UNREAD_CHAT_MESSAGE_EVENT, JSON.stringify(toJS(this.unreadMessages)));
            STORE.generalNotifications.deactivateReadMessagesFromNotifications(taskId);
        }
    }
    clearAllUnreadMessagesFromStore() {
        this.unreadMessages.clear();
        sessionStorage.removeItem(UNREAD_CHAT_MESSAGE_EVENT);
    }
    setMediaViewInitState(mediaState) {
        this.mediaViewInitState = mediaState;
    }
    setImiWidgetInitState(state) {
        this.imiWidgetInitState = state;
    }
    setImiServicesInitialized(flag) {
        this.imiServicesInitialized = flag;
    }
    get chatMessages() {
        return this.chatMessage;
    }
    get mediaInitState() {
        return toJS(this.mediaViewInitState);
    }
    get imiInitState() {
        return this.imiWidgetInitState;
    }
    get imiServicesInit() {
        return this.imiServicesInitialized;
    }
    setRetryMediaViewInit() {
        this.retryMediaViewInit += 1;
    }
}
__decorate([
    observable
], ModuleChat.prototype, "chatMessage", void 0);
__decorate([
    observable
], ModuleChat.prototype, "mediaViewInitState", void 0);
__decorate([
    observable
], ModuleChat.prototype, "retryMediaViewInit", void 0);
__decorate([
    observable
], ModuleChat.prototype, "removeMediaListener", void 0);
__decorate([
    observable
], ModuleChat.prototype, "hasCustomerLeft", void 0);
__decorate([
    observable
], ModuleChat.prototype, "isConvNotFound", void 0);
__decorate([
    observable
], ModuleChat.prototype, "unreadMessages", void 0);
__decorate([
    observable
], ModuleChat.prototype, "imiTeamId", void 0);
__decorate([
    observable
], ModuleChat.prototype, "imiAgentId", void 0);
__decorate([
    observable
], ModuleChat.prototype, "imiConvId", void 0);
__decorate([
    observable
], ModuleChat.prototype, "imiWidgetInitState", void 0);
__decorate([
    observable
], ModuleChat.prototype, "imiServicesInitialized", void 0);
__decorate([
    observable
], ModuleChat.prototype, "newUnreadTaskMessage", void 0);
__decorate([
    computed
], ModuleChat.prototype, "unReadMessagesObject", null);
__decorate([
    computed
], ModuleChat.prototype, "getNewUnreadMessageId", null);
__decorate([
    computed
], ModuleChat.prototype, "mediaViewOption", null);
__decorate([
    action
], ModuleChat.prototype, "setChatMessages", null);
__decorate([
    action
], ModuleChat.prototype, "setUnreadMessages", null);
__decorate([
    action
], ModuleChat.prototype, "fireChatMessageNotification", null);
__decorate([
    action
], ModuleChat.prototype, "getUnreadMessagesFromSessionStorage", null);
__decorate([
    action
], ModuleChat.prototype, "updateRemoveMediaListeners", null);
__decorate([
    action
], ModuleChat.prototype, "updateHasCustomerLeft", null);
__decorate([
    action
], ModuleChat.prototype, "updateIsConvNotFound", null);
__decorate([
    action
], ModuleChat.prototype, "deleteUnreadMessages", null);
__decorate([
    action
], ModuleChat.prototype, "clearAllUnreadMessagesFromStore", null);
__decorate([
    action
], ModuleChat.prototype, "setMediaViewInitState", null);
__decorate([
    action
], ModuleChat.prototype, "setImiWidgetInitState", null);
__decorate([
    action
], ModuleChat.prototype, "setImiServicesInitialized", null);
__decorate([
    computed
], ModuleChat.prototype, "chatMessages", null);
__decorate([
    computed
], ModuleChat.prototype, "mediaInitState", null);
__decorate([
    computed
], ModuleChat.prototype, "imiInitState", null);
__decorate([
    computed
], ModuleChat.prototype, "imiServicesInit", null);
__decorate([
    action
], ModuleChat.prototype, "setRetryMediaViewInit", null);
