var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import { action, computed, observable } from "mobx";
export default class ModuleErrorDialog {
    constructor() {
        this.errorDetails = {};
        this.isModalOpen = false;
        this.errorDetailsList = [];
        this.setErrorDetails = (e) => {
            this.errorDetailsList.push(e.detail);
            if (this.isModalOpen) {
                this.updateModalStatus(false);
            }
            if (!this.isModalOpen) {
                this.shiftErrorDetail();
                this.updateModalStatus(true);
            }
        };
        this.updateModalStatus = (e) => {
            this.isModalOpen = e;
            if (!e) {
                if (this.errorDetailsList.length >= 1) {
                    //when modal close trigerred check for existing errors
                    this.shiftErrorDetail();
                    this.isModalOpen = true;
                }
                else {
                    this.errorDetails = {};
                    this.isModalOpen = false;
                    this.errorDetailsList = [];
                }
            }
        };
    }
    get localization() {
        return {
            okButtonText: t("app:errorModal:ok"),
            headerText: t("app:errorModal:header"),
            errorActionsText: t("app:errorModal:errorActions")
        };
    }
    /**
     * Actions
     */
    shiftErrorDetail() {
        this.errorDetails = Object.assign(Object.assign({}, this.errorDetailsList.shift()), { localization: {
                errorLessButtonText: t("app:errorDetail:errorLessButton"),
                errorMoreButtonText: t("app:errorDetail:errorMoreButton"),
                errorDetailsText: t("app:errorModal:errorDetails"),
                trackingIdTitleText: t("app:errorModal:trackingIdTitle"),
                trackingIdDescriptionText: t("app:errorModal:trackingIdDescription"),
                trackingIdLabelText: t("app:errorModal:trackingIdLabel"),
                copyText: t("app:errorModal:copy"),
                copiedText: t("app:errorModal:copied")
            } });
    }
}
__decorate([
    observable
], ModuleErrorDialog.prototype, "errorDetails", void 0);
__decorate([
    observable
], ModuleErrorDialog.prototype, "isModalOpen", void 0);
__decorate([
    computed
], ModuleErrorDialog.prototype, "localization", null);
__decorate([
    action
], ModuleErrorDialog.prototype, "shiftErrorDetail", null);
__decorate([
    action
], ModuleErrorDialog.prototype, "setErrorDetails", void 0);
__decorate([
    action
], ModuleErrorDialog.prototype, "updateModalStatus", void 0);
