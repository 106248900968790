var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RouteName } from "@/app/routing/routing";
import { STORE } from "@/store";
import { action, computed, observable } from "mobx";
import { getLayoutConfigFromCache } from "../../src/app/utils/DesktopLayoutUtils";
import { getDefaultPanelComp, isPanelEmpty, updateHomepageResponsiveLayout, updateNavigationResponsiveLayout, updatePanelResponsiveLayout, updatePersistentResponsiveLayout } from "../../src/app/utils/UtilsResponsive";
import { DEFAULT_ACTION_ITEMS_ORDER } from "../app/components/constants";
export class ModuleDynamic {
    constructor() {
        this.isDragDrop = true;
        this.header = null;
        this.advancedHeader = null;
        this.actionIcons = DEFAULT_ACTION_ITEMS_ORDER;
        this.panel = null;
        this.persistent = null;
        this.widgetExplorer = null;
        this.navigation = null;
        this.homepage = null;
        this.headless = null;
        this.isConnector = false;
        this.currentRoute = "";
        this.persistentConfig = null;
        this.respPersistentConfig = null;
        this.navConfig = null;
        this.respNavConfig = null;
        this.homepageConfig = null;
        this.resHomepageConfig = null;
        this.updateLayoutForConnectorViewUnresponsiveWidget = (agentXAppRouting) => __awaiter(this, void 0, void 0, function* () {
            if (this.panelConfig) {
                this.updatePanel(this.panelConfig);
            }
            if (this.persistentConfig) {
                this.updatePersistent(this.persistentConfig);
            }
            if (this.navConfig) {
                this.updateNavigation(this.navConfig);
                const layoutConfig = getLayoutConfigFromCache();
                const roleSpecificLayoutDetails = layoutConfig.layout[layoutConfig.userSelectedRole];
                if (this.isConnector) {
                    if (roleSpecificLayoutDetails && this.navigation) {
                        roleSpecificLayoutDetails.area.navigation = this.navigation;
                    }
                    yield agentXAppRouting.addOrUpdateDynamicRoutes(roleSpecificLayoutDetails);
                }
                else {
                    yield agentXAppRouting.addOrUpdateDynamicRoutes(roleSpecificLayoutDetails);
                }
            }
            if (this.homepageConfig) {
                this.updateHomePage(this.homepageConfig);
                const layoutConfig = getLayoutConfigFromCache();
                const roleSpecificLayoutDetails = layoutConfig.layout[layoutConfig.userSelectedRole];
                if (this.isConnector) {
                    if (roleSpecificLayoutDetails && this.homepage) {
                        roleSpecificLayoutDetails.area.homepage = this.homepage;
                    }
                    yield agentXAppRouting.addOrUpdateDynamicRoutes(roleSpecificLayoutDetails);
                }
                else {
                    yield agentXAppRouting.addOrUpdateDynamicRoutes(roleSpecificLayoutDetails);
                }
            }
        });
        // For debug of "config_sample.json" - page-dynamic-widgets-debug
        // --------------------------------------------------------------
        this.testStr = "Title 1 [from STORE]";
        this.testObj = { sub: { sub: { val: "sub-000" } } };
        this.testArr = ["arr-0"];
        this.testBool = true;
        this.testNotObservable = "NotObservable works";
        // --------------------------------------------------------------
    }
    updateSelectedRoute(route) {
        this.currentRoute = route;
    }
    get selectedRouted() {
        return this.currentRoute;
    }
    updatePanel(v) {
        this.panel = v;
        if (!this.panelConfig) {
            this.panelConfig = v;
            this.respPanelConfig = updatePanelResponsiveLayout(v);
        }
        if (this.isConnector) {
            this.panel = this.respPanelConfig || null;
        }
    }
    resetResponsiveLayout() {
        this.respPersistentConfig = null;
        this.persistentConfig = null;
        this.respPanelConfig = undefined;
        this.panelConfig = undefined;
        this.navConfig = null;
        this.homepageConfig = null;
        this.respNavConfig = null;
    }
    updatePanelPersistentAttributes() {
        var _a;
        if ((_a = this.panel) === null || _a === void 0 ? void 0 : _a.attributes) {
            this.panel.attributes["persist-selection"] = true;
            this.panel.attributes["tabs-id"] = "$STORE.agentContact.taskSelected.interactionId";
        }
    }
    updateNavigation(v) {
        this.navigation = v;
        if (!this.navConfig) {
            this.navConfig = v;
            this.respNavConfig = updateNavigationResponsiveLayout(v);
        }
        if (this.isConnector) {
            this.navigation = this.respNavConfig;
        }
    }
    updateHomePage(homenav) {
        if (!homenav) {
            this.homepage = null;
            this.resHomepageConfig = null;
        }
        else {
            this.homepage = homenav;
            if (!this.homepageConfig) {
                this.homepageConfig = homenav;
                this.resHomepageConfig = updateHomepageResponsiveLayout(homenav);
            }
            if (this.isConnector) {
                this.homepage = this.resHomepageConfig;
            }
        }
    }
    updatePanelWithPersistent() {
        var _a;
        if (this.persistent && ((_a = this.persistent) === null || _a === void 0 ? void 0 : _a.length) > 0 && this.panel) {
            if (isPanelEmpty(this.panel)) {
                this.panel = getDefaultPanelComp();
                if (this.panel) {
                    this.panel.children = this.persistent;
                }
                return;
            }
            if (this.panel.children) {
                this.panel.children = this.panel.children.concat(this.persistent);
            }
        }
    }
    updatePersistent(v) {
        this.persistent = v;
        if (!this.persistentConfig) {
            this.persistentConfig = v;
            this.respPersistentConfig = updatePersistentResponsiveLayout(v);
        }
        if (this.isConnector) {
            this.persistent = this.respPersistentConfig;
        }
        this.updatePanelWithPersistent();
    }
    updateScreenSize(v) {
        this.isConnector = v;
    }
    updateIsDragDrop(v) {
        this.isDragDrop = v;
    }
    updateHeader(v) {
        this.header = v;
    }
    updateAdvancedHeader(v) {
        this.advancedHeader = v;
    }
    updateWidgetExplorer(v) {
        this.widgetExplorer = v;
    }
    updateActionIcons(v) {
        this.actionIcons = v;
    }
    updateHeadless(v) {
        this.headless = v;
    }
    get persistentWidgets() {
        const tabContent = this.persistent && this.persistent[1] && this.persistent[1].children && this.persistent[1].children[0]
            ? this.persistent[1].children[0]
            : [];
        return this.persistent && this.persistent.length > 2
            ? {
                comp: "md-tabs",
                attributes: { class: "widget-tabs", "persist-selection": true, "tabs-id": "agentx-persistent-widget-tabs" },
                children: this.persistent
            }
            : tabContent;
    }
    get showPersistentWidgets() {
        return (STORE.routing.currentRouteName !== RouteName.TASK &&
            STORE.routing.currentRouteName !== RouteName.TASKPANEL &&
            STORE.dynamic.persistent &&
            STORE.dynamic.persistent.length > 0);
    }
    updTest() {
        this.testStr = `Title CHANGE ${Math.random()}`;
        this.testObj.sub.sub.val = `sub-${Math.random() * 100}`;
        if (this.testArr.length < 10) {
            this.testArr = [...this.testArr, `arr-${Math.random() * 100}`];
        }
        else {
            this.testArr = ["arr-0"];
        }
        this.testBool = this.testBool ? false : true;
    }
}
__decorate([
    observable
], ModuleDynamic.prototype, "isDragDrop", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "header", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "advancedHeader", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "actionIcons", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "panel", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "persistent", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "widgetExplorer", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "navigation", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "homepage", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "headless", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "isConnector", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "currentRoute", void 0);
__decorate([
    action
], ModuleDynamic.prototype, "updateSelectedRoute", null);
__decorate([
    computed
], ModuleDynamic.prototype, "selectedRouted", null);
__decorate([
    action
], ModuleDynamic.prototype, "updatePanel", null);
__decorate([
    action
], ModuleDynamic.prototype, "resetResponsiveLayout", null);
__decorate([
    action
], ModuleDynamic.prototype, "updatePanelPersistentAttributes", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateNavigation", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateHomePage", null);
__decorate([
    action
], ModuleDynamic.prototype, "updatePersistent", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateScreenSize", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateIsDragDrop", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateHeader", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateAdvancedHeader", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateWidgetExplorer", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateActionIcons", null);
__decorate([
    action
], ModuleDynamic.prototype, "updateHeadless", null);
__decorate([
    computed
], ModuleDynamic.prototype, "persistentWidgets", null);
__decorate([
    computed
], ModuleDynamic.prototype, "showPersistentWidgets", null);
__decorate([
    observable
], ModuleDynamic.prototype, "testStr", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "testObj", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "testArr", void 0);
__decorate([
    observable
], ModuleDynamic.prototype, "testBool", void 0);
__decorate([
    action
], ModuleDynamic.prototype, "updTest", null);
export const dynamic = new ModuleDynamic();
