var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable } from "mobx";
export class ModuleEnvVaribles {
    constructor() {
        this.serviceUrls = {
            dialerHost: process.env.DIALER_HOST,
            aqmHost: process.env.AQM_HOST,
            notifHost: process.env.NOTIF_HOST,
            emmHost: process.env.EMM_HOST,
            wxmHost: process.env.WXM_HOST,
            configUrl: process.env.CONFIG_URL,
            analyzerHost: process.env.ANALYZER_HOST,
            analyzerNewHost: process.env.ANALYZER_NEW_HOST,
            hostUrl: process.env.HOST_URL,
            mediServiceHost: process.env.MEDIA_SERVICE_HOST,
            webexAppUrl: process.env.WEBEX_APP_URL,
            apiGatewayHost: process.env.API_GATEWAY,
            agentxBackendServiceHost: process.env.AGENTX_BACKEND_PROXY_SERVICE_HOST,
            welcomeTimeout: process.env.WELCOME_TIMEOUT,
            dialerProxyHost: process.env.DIALER_PROXY_HOST,
            jabberHostUrl: process.env.JABBER_HOST_URL
        };
    }
}
__decorate([
    observable
], ModuleEnvVaribles.prototype, "serviceUrls", void 0);
export const envVaribles = new ModuleEnvVaribles();
