import { Router } from "@uuip/unified-ui-platform";
import { css } from "lit-element";
import { html, nothing } from "lit-html";
import { STORE } from "@/store";
const SupervisorBlock = () => {
    return new Router.RouterView.Block({
        template: html `
      <!-- SUPERVISOR BLOCK -->
      <div class="supervisor-block">
        <h1>Home Screen</h1>
        ${STORE.app.isStationLoginSuccess
            ? html `
              <div class="supervisor-login">
                <div class="heading-text">
                  <h1>Supervisor Login</h1>
                  <h2>Hi ${STORE.agent.agentName} !</h2>
                </div>
              </div>
            `
            : nothing}
      </div>
    `,
        styles: css `
      .supervisor-block {
        max-width: 100%;
        text-align: left;
        width: 80rem;
      }

      .supervisor-login {
        height: 100%;
        position: relative;
        text-align: center;
      }

      .heading-text {
        left: 23%;
        position: absolute;
        top: 26%;
      }
    `
    });
};
export default SupervisorBlock;
