var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import "@agentx/agentx-mfe-wc-based";
import "@momentum-ui/web-components";
import { LitElement, customElement, html, internalProperty, property, query } from "lit-element";
import style from "./GotoAnalyzer.scss";
import { ADD_FOCUS_AGENTX_GOTO_ANALYZER, Key } from "./constants";
/**
 * @element agentx-wc-goto-analyzer
 */
let GotoAnalyzer = class GotoAnalyzer extends LitElement {
    constructor() {
        super(...arguments);
        this.environment = process.env.CC_DC;
        this.analyzerLink = `${STORE.envVaribles.serviceUrls.analyzerHost}/analyzer/home`;
        this.addFocusToAnchorTag = () => {
            var _a;
            let anchorTagElement = null;
            anchorTagElement = (_a = this.gotoAnalyzerElement.shadowRoot) === null || _a === void 0 ? void 0 : _a.querySelector("a");
            setTimeout(() => anchorTagElement.focus(), 10);
        };
        this.goToAnalyzer = (event) => {
            if (!this.checkKeyboardEventKey(event)) {
                return;
            }
            window.open(this.analyzerLink);
        };
    }
    static get styles() {
        return style;
    }
    connectedCallback() {
        super.connectedCallback();
        document.addEventListener(ADD_FOCUS_AGENTX_GOTO_ANALYZER, this.addFocusToAnchorTag);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener(ADD_FOCUS_AGENTX_GOTO_ANALYZER, this.addFocusToAnchorTag);
    }
    checkKeyboardEventKey(event) {
        if (event.type === "keydown") {
            const { code } = event;
            if (code !== Key.Space && code !== Key.Enter) {
                return false;
            }
        }
        return true;
    }
    render() {
        return html `
      <md-link
        id="goto-analyzer-link"
        aria-label=${t("app:common.gotoAnalyzerTab")}
        @click=${(event) => {
            event.preventDefault();
            this.goToAnalyzer(event);
        }}
        @keydown=${(event) => this.goToAnalyzer(event)}
        tag="a"
      >
        <span class="goto-text">${t("app:common.gotoAnalyzer")}</span>
      </md-link>
    `;
    }
};
__decorate([
    property()
], GotoAnalyzer.prototype, "environment", void 0);
__decorate([
    internalProperty()
], GotoAnalyzer.prototype, "analyzerLink", void 0);
__decorate([
    query("#goto-analyzer-link")
], GotoAnalyzer.prototype, "gotoAnalyzerElement", void 0);
GotoAnalyzer = __decorate([
    customElement("agentx-wc-goto-analyzer")
], GotoAnalyzer);
export { GotoAnalyzer };
