import { SERVICE } from "@agentx/agentx-services";
import { auth } from "../store-auth";
export var AuthSharedActions;
(function (AuthSharedActions) {
    AuthSharedActions.Init = () => {
        // Bind auth store actions channels
        // GetToken
        {
            const d = SERVICE.actionsChannels.createDestination("getToken/Req");
            const s = SERVICE.actionsChannels.createSource("getToken/Res");
            /* istanbul ignore next */
            d.addListener(({ args: [req_uuid] }) => s.send(auth.accessToken, req_uuid));
        }
    };
})(AuthSharedActions || (AuthSharedActions = {}));
