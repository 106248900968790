var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { t } from "@/mixins/i18nMixin";
import { customElement, html, LitElement } from "lit-element";
import style from "./ChangeTeamLoading.scss";
let ChangeTeamLoading = class ChangeTeamLoading extends LitElement {
    constructor() {
        super(...arguments);
        this.languageResources = "/i18n/{{lng}}/{{ns}}.json";
    }
    static get styles() {
        return style;
    }
    render() {
        return html `
      <div class="loading-screen">
        <div class="overlay-message">
          ${html `
            <md-spinner size="80"></md-spinner>
            <p class="change-team-loading-header">${t("app:changeTeam.changeTeamLoadingHeader")}</p>
            <p class="change-team-loading-message">${t("app:changeTeam.changeTeamLoadingMessage")}</p>
          `}
        </div>
      </div>
    `;
    }
};
ChangeTeamLoading = __decorate([
    customElement("change-team-loading")
], ChangeTeamLoading);
export { ChangeTeamLoading };
