import { t } from "@/mixins/i18nMixin";
import { STORE } from "@/store";
import { SERVICE } from "@agentx/agentx-services";
import { AGENT_STATE_CHANGEDBY_NAME, ALERT_BANNER_DETAILS, AX_ANNOUNCEMENT_UPDATE, STATE_CHANGE_BANNER } from "../components/constants";
export const showBanner = (e) => {
    var _a;
    const ele = document.createElement("div");
    ele.innerHTML = `${e.detail.bannerText}`;
    STORE.alertBanner.updateAlertBanner({
        message: ele,
        display: true,
        type: "default",
        closable: true,
        id: e.detail.id ? e.detail.id : STATE_CHANGE_BANNER,
        onHide: () => {
            if (e.detail.bannerType === AGENT_STATE_CHANGEDBY_NAME ||
                e.detail.bannerType === SERVICE.constants.WebexConstants.AX_PRESENCE_STATE_UPDATE) {
                sessionStorage.removeItem(ALERT_BANNER_DETAILS);
                if (!STORE.agentContact.getEngagedStatus) {
                    STORE.agent.updateChangeByName(null);
                }
            }
        }
    });
    window.dispatchEvent(new CustomEvent(AX_ANNOUNCEMENT_UPDATE, {
        detail: {
            thisEvent: {
                message: `${e.detail.bannerText}`
            }
        }
    }));
    if (e.detail.bannerType === AGENT_STATE_CHANGEDBY_NAME) {
        // to handle click on X button on banner
        STORE.agent.updateChangeByName(`${(_a = e.detail) === null || _a === void 0 ? void 0 : _a.supervisorName}`);
        STORE.browserNotifications.firebrowserNotification(`${e.detail.supervisorName} ${t("app:tpw.stateChange.changedStateTo")} ${STORE.agent.subStatus}.`);
    }
    else if (e.detail.bannerType === SERVICE.constants.WebexConstants.AX_PRESENCE_STATE_UPDATE) {
        STORE.browserNotifications.firebrowserNotification(`${t("app:presenceSyncStateManagement.axAgentStateChanged")} ${STORE.agent.subStatus} ${t("app:presenceSyncStateManagement.axAgentSync")}`);
    }
};
export const hideBanner = (bannerType) => {
    STORE.alertBanner.updateAlertBanner({
        display: false,
        id: ""
    });
    if (bannerType === AGENT_STATE_CHANGEDBY_NAME) {
        STORE.agent.updateChangeByName(null);
    }
    sessionStorage.removeItem(ALERT_BANNER_DETAILS);
};
